//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-660:_552,_3398,_1412,_6804,_2178,_576,_6140,_1544;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-660')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-660', "_552,_3398,_1412,_6804,_2178,_576,_6140,_1544");
        }
      }catch (ex) {
        console.error(ex);
      }