//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-660:_5116,_2316,_1574,_3992,_7496,_3598,_7096,_3840;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-660')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-660', "_5116,_2316,_1574,_3992,_7496,_3598,_7096,_3840");
        }
      }catch (ex) {
        console.error(ex);
      }