const vinSearchFields = ['VIN', 'EIN', 'Vehicle'];

const priceLevel = globalThis.Convermax.cartpricelevel ? btoa(globalThis.Convermax.cartpricelevel) : '';

globalThis.Convermax.quickView = quickView;

const getTermFromBreadcrumbs = () =>
  [...window.document.querySelectorAll('.breadcrumbs a, .breadcrumnb li, .breadcrumb a')]
    .map((elem) => {
      const text = (elem.innerText || elem.textContent).trim();
      const ignore = elem.id === 'catCrumbHomeLink' || text === 'Home' || elem.getAttribute('data-cm-ignore');
      return !ignore && text;
    })
    .filter(Boolean)
    .join('>');

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
    queryCorrectionLevel: 'No',
    sort: 'Name',
    extra: {
      ['plv_id']: priceLevel,
    },
  },
  fitmentSearch: {
    baseFields: vinSearchFields,
  },
  autocomplete: {
    queryCorrectionLevel: 'No',
    requestDefaults: {
      extra: {
        ['plv_id']: priceLevel,
      },
    },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#extrapage > .container',
      template: 'SearchPage',
      visibleIf: { selector: '#cm_results' },
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#category .category-products',
      template: 'SearchPage',
      visibleIf: () => !getTermFromBreadcrumbs().startsWith('Owner Resources>'),
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'Name': 'Name',
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'DateCreated:desc': 'Newest',
      },
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: '#searchBox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.head-mobile-nav [data-toggle]',
        wrapper: 'a',
        class: 'pull-right cm_search-box_container__mobile',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'TabletSearchBoxDialogButton',
      type: 'SearchBoxDialogButton',
      location: {
        replace: '.top_links [data-toggle]',
        wrapper: 'a',
        class: 'visible-sm cm_search-box_container__tablet',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    { name: 'VehicleWidget' },
    {
      name: 'Garage',
      location: { insertBefore: '.top_links .cart', wrapper: 'a' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'SingleVehicleGarage',
      location: { insertAfter: '.mobile-menu-widget', class: 'mobile-menu-widget' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};

function quickView({ CatalogID, ProductLink }) {
  return (e) => {
    e.preventDefault();
    const urlToOpen = CatalogID ? `/product.asp?lt_c=1&itemid=${CatalogID}&qv=1` : ProductLink;
    window.quickview(urlToOpen);
  };
}
